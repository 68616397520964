export interface SectionTabType {
  activeBgColor: string;
  activeTextColor: string;
  anchor: string;
  key: string;
  text: string;
}

export enum HomepageInterest {
  DESIGN = 'DESIGN',
  SOURCING = 'SOURCING',
  LOGISTICS = 'LOGISTICS',
  STOREFRONT = 'STOREFRONT',
  MARKETING = 'MARKETING',
  ANALYTICS = 'ANALYTICS',
}

export interface PlatformDisplayItemType {
  key: HomepageInterest;
  buttonText: string;
  desc: string;
  image: string;
  path: string;
}

export interface HomepageTestimonial {
  author: {
    brand: string;
    name: string;
  };
  bgColor: string;
  image: string;
  isTextLeft: boolean;
  review: {
    color: string;
    text: string;
  };
  title: {
    color: string;
    text: string;
  };
}

export interface Vimeo {
  id: number;
  padding: string;
}

export interface HomepagePlatformSectionButton {
  text: string;
  color: string;
  bgColor: string;
  borderColor?: string;
  path: string;
}

export interface HomepagePlatformSection {
  id: string;
  isTextLeft: boolean;
  image: string;
  vimeo?: Vimeo;
  preTitle: {
    text: string;
    color: string;
  };
  title: {
    text: string;
    color: string;
  };
  description: {
    text: string;
    color: string;
  };
  buttons: HomepagePlatformSectionButton[];
  withPietraComparator: {
    titleGradient: string;
    borderColor: string;
    backgroundColor: string;
    withBullets: string[];
    withoutBullets: string[];
  };
  testimonial: HomepageTestimonial;
  playIcon?: boolean;
}

export interface HomepageFeatureCard {
  img: string;
  title: string;
  desc: string;
  path: string;
  external?: boolean;
  vimeoId?: number;
}
