import { STATIC_FILE_URL } from '@/shared/constants';
import {
  HomepageFeatureCard,
  HomepagePlatformSection,
  PlatformDisplayItemType,
  SectionTabType,
  HomepageInterest,
} from '../types/homepage';

export const SECTION_TABS: SectionTabType[] = [
  {
    activeBgColor: '#ff5c3c',
    activeTextColor: '#fff',
    anchor: '#sourcing',
    key: 'sourcing',
    text: 'Sourcing & Production',
  },
  {
    activeBgColor: '#f9e070',
    activeTextColor: '#000',
    anchor: '#order-fulfillment',
    key: 'order-fulfillment',
    text: 'Logistics & Fulfillment',
  },
  {
    activeBgColor: '#57ad6a',
    activeTextColor: '#fff',
    anchor: '#sales-tools',
    key: 'sales-tools',
    text: 'Sales and Marketing Tools',
  },
];

export const PLATFORM_DISPLAY_ITEMS: PlatformDisplayItemType[] = [
  {
    key: HomepageInterest.DESIGN,
    buttonText: 'Design',
    image: '/svg/nav-icon-design.svg',
    path: '/platform/design-studio',
    desc: 'Use AI to create mood boards and ideate.',
  },
  {
    key: HomepageInterest.SOURCING,
    buttonText: 'Sourcing',
    image: '/svg/nav-icon-sourcing.svg',
    path: '/platform/sourcing-and-production',
    desc: 'Sourcing products & packaging at low costs.',
  },
  {
    key: HomepageInterest.LOGISTICS,
    buttonText: 'Logistics',
    image: '/svg/nav-icon-logistics.svg',
    path: '/platform/logistics-and-fulfillment',
    desc: 'Saving thousands on order fulfillment.',
  },
  {
    key: HomepageInterest.STOREFRONT,
    buttonText: 'Storefront',
    image: '/svg/nav-icon-storefront.svg',
    path: 'https://creator.pietrastudio.com/homebase?tab=STOREFRONT',
    desc: 'No fuss storefronts to start selling fast.',
  },
  {
    key: HomepageInterest.MARKETING,
    buttonText: 'Marketing',
    image: '/svg/nav-icon-marketing.svg',
    path: '/platform/sales-and-marketing',
    desc: 'Popular software at steep discounts.',
  },
  {
    key: HomepageInterest.ANALYTICS,
    buttonText: 'Analytics',
    image: '/svg/nav-icon-analytics.svg',
    path: '/platform/analytics',
    desc: 'Get data to grow your sales faster.',
  },
];

export const HOMEPAGE_TAB_SECTIONS: HomepagePlatformSection[] = [
  {
    id: 'sourcing',
    isTextLeft: true,
    image: `${STATIC_FILE_URL}/db3a3ca8c4eb96ffdffe5cb9026c6395.png`,
    vimeo: { id: 857911936, padding: '58.06% 0 0 0' },
    preTitle: {
      text: 'Sourcing & Production',
      color: '#FF5C3C',
    },
    title: {
      text: 'Work with the world’s best factories',
      color: '#000',
    },
    description: {
      text: 'Exclusive to Pietra members.',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    buttons: [
      {
        text: 'Learn more',
        color: '#fff',
        bgColor: '#FF5C3C',
        path: '/platform/sourcing-and-production/products',
      },
    ],
    withPietraComparator: {
      titleGradient: 'linear-gradient(95deg, #FF5C3C 45.89%, #F5BE32 118.46%)',
      borderColor: '#FF5C3C',
      backgroundColor: '#F9F2F1',
      withBullets: [
        'Streamlined invoicing & tracking',
        'Access to 1300+ vetted factories',
        'Sourcing experts available to assist',
        'Low minimums & quick lead times',
      ],
      withoutBullets: ['Messy email management', 'Hire brokers or consultants', 'High minimums required'],
    },
    testimonial: {
      author: {
        brand: 'Cannabis Products Brand',
        name: 'Houseplant',
      },
      bgColor: '#FF5C3C',
      image: 'https://static.pietrastudio.com/public/file_uploads/c70b21e41a4c35e54d5ad8080b380a48.png',
      isTextLeft: false,
      review: {
        color: '#FAE1DC',
        text: 'Pietra is a great resource for all your product needs. Working with their team has made development a seamless process!',
      },
      title: {
        color: '#fff',
        text: 'Houseplant decreased their sourcing costs by 50%.',
      },
    },
  },
  {
    id: 'order-fulfillment',
    isTextLeft: true,
    image: `${STATIC_FILE_URL}/0f86d08225339edd249a48f47fc39e25.png`,
    vimeo: {
      id: 857926029,
      padding: '60.4% 0 0 0',
    },
    preTitle: {
      text: 'Logistics & Fulfillment',
      color: '#f9e070',
    },
    title: {
      text: 'Storage & order fulfillment for SMBs',
      color: '#000',
    },
    description: {
      text: 'Pietra members save 40-50% on storage, fulfillment, and shipping.',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    buttons: [
      {
        text: 'Learn more',
        color: '#000',
        bgColor: '#f9e070',
        path: '/platform/logistics-and-fulfillment',
      },
    ],
    withPietraComparator: {
      titleGradient: 'linear-gradient(95deg, #D09808 45.89%, #64F30D 118.46%)',
      borderColor: '#C5A827',
      backgroundColor: '#FFFBE7',
      withBullets: ['No minimums, no set up fees', '$1.15/order unlimited picks', 'Human account manager & CX'],
      withoutBullets: ['Setup fees of $500+', '$2.50+/order fees', 'Automated customer support'],
    },
    testimonial: {
      author: {
        brand: 'Bad Mouth, Organic Candy Brand',
        name: 'Kasey Stewart',
      },
      bgColor: '#f9e070',
      image: 'https://static.pietrastudio.com/public/file_uploads/becae2f023bc78fda01d27c7b20c27b0.png',
      isTextLeft: false,
      review: {
        color: '#58502B',
        text: 'Pietra has saved us time & money by partnering with the right suppliers. Their fulfillment center has also been incredible to work with in regards to customer service, low incidents, and shipping speed.',
      },
      title: {
        color: '#000',
        text: 'Bad Mouth reduced their fulfillment incidents by 10% with Pietra.',
      },
    },
  },
  {
    id: 'sales-tools',
    isTextLeft: true,
    image: `${STATIC_FILE_URL}/f3a5165ce986dd2b969196288c3864ff.png`,
    preTitle: {
      text: 'Sales and Marketing Tools',
      color: '#57AD6A',
    },
    title: {
      text: 'Software to grow on every channel',
      color: '#000',
    },
    description: {
      text: 'From email list building to retail matching software. Pietra helps you grow your brand.',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    buttons: [
      {
        text: 'Learn more',
        color: '#fff',
        bgColor: '#57AD6A',
        path: '/platform/sales-and-marketing',
      },
    ],
    withPietraComparator: {
      titleGradient: 'linear-gradient(95deg, #4AB409 45.89%, #09B0B0 118.46%)',
      borderColor: '#57AD6A',
      backgroundColor: '#EBF5ED',
      withBullets: ['TikTok Shop', 'Retail Distribution', 'Website optimization'],
      withoutBullets: ['Agency and retainer fees', 'Lock in contracts'],
    },
    testimonial: {
      author: {
        brand: 'Gel Blasters',
        name: 'Oren Schauble',
      },
      bgColor: '#57AD6A',
      image: 'https://static.pietrastudio.com/public/file_uploads/782980d8f3bad5ff58f0dfd404be40d7.png',
      isTextLeft: false,
      review: {
        color: '#DBECDE',
        text: "I can't believe we were leaving that much money on the table. Pietra's audience capture tools are a gamechanger for anyone in commerce.",
      },
      title: {
        color: '#fff',
        text: 'Gel Blasters is an 8 figure brand leveraging Pietra to supercharge their marketing',
      },
    },
  },
];

export const FEATURE_CARDS: HomepageFeatureCard[] = [
  {
    title: 'Product design tools',
    desc: 'AI-powered creative tools to bring product & packaging ideas to life.',
    img: `${STATIC_FILE_URL}/445c9a2a3b3b794a92e5116b70a0d12f.png`,
    path: '/platform/design-studio',
    vimeoId: 905396529,
  },
  {
    title: 'Sourcing & Production',
    desc: 'Marketplace of vetted factories for products, packaging, and creative services.',
    img: `${STATIC_FILE_URL}/019bc765a9c56085701ccebba26b0542.png`,
    path: '/platform/sourcing-and-production',
    vimeoId: 905327233,
  },
  {
    title: 'Order fulfillment',
    desc: 'Infrastructure to store and ship products around the world from your website.',
    img: `${STATIC_FILE_URL}/9c112bdaf71d27b7b12cd05fd7911656.png`,
    path: '/platform/logistics-and-fulfillment',
    vimeoId: 905373249,
  },
  {
    title: 'E-commerce storefront',
    desc: 'Create a beautiful website in minutes to start selling fast.',
    img: `${STATIC_FILE_URL}/8ac96eb9e7b5d8e7df82b1892f724465.png`,
    path: '/platform/sales-and-marketing',
    vimeoId: 905460243,
  },
  {
    title: 'Website Email Capture',
    desc: 'Build your email list from website visitors.',
    img: `${STATIC_FILE_URL}/a7eab2cc7e4575fc57f878d302b69211.png`,
    path: '/platform/email-capture',
    vimeoId: 906475808,
  },
  {
    title: 'Affiliate Marketing',
    desc: 'Set up and run an influencer marketing program.',
    img: `${STATIC_FILE_URL}/2cb0df5c50e9168bb3b35f21d399ac6f.png`,
    path: '/platform/affiliate-marketing',
    vimeoId: 906475808,
  },
  {
    title: 'Data & Dashboards',
    desc: '35+ business dashboards to understand your business.',
    img: `${STATIC_FILE_URL}/cc0628988f0c9b2a2d031affc45c62b0.png`,
    path: '/platform/analytics',
    vimeoId: 905759953,
  },
  {
    title: 'Print on demand',
    desc: 'Make merch and promotional products at heavily discounted rates.',
    img: `${STATIC_FILE_URL}/daaf49beeb9a4e1bc4107243ffe9cbfa.png`,
    path: '/platform/print-on-demand',
    vimeoId: 905737521,
  },
  {
    title: 'Business planning tools',
    desc: 'Get a step-by-step guide to building an online business. Great for beginners getting started.',
    img: `${STATIC_FILE_URL}/23ff97a90a53847d0668beb022d12ae9.png`,
    path: 'https://builder.pietrastudio.com',
    external: true,
    vimeoId: 906172756,
  },
  {
    title: 'Weekly workshops',
    desc: 'Get access to professionals each week to help you maximize Pietra.',
    img: `${STATIC_FILE_URL}/60fd924583a71e41216b6fa0d4e14529.png`,
    path: '/sell-on-pietra/register',
  },
];
